import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

// Icons
import QuestionMarkIcon from "../assets/icons/question-mark.svg"
import ArrowIcon from "../assets/icons/arrow-down.svg"

// Styles
import "../styles/components/FAQ-inpage.scss"

const FAQInpage = ({
  items,
  selectedItem,
  setSelectedItem,
  hasInlineAnswer,
}) => {
  return (
    <div className="is-card FAQ-inpage">
      <span className="icon question-mark is-large has-text-white">
        <QuestionMarkIcon />
      </span>
      {items.map(item => (
        <React.Fragment key={item.title}>
          <div
            onClick={() => setSelectedItem(item)}
            className={`item  ${
              item.title === selectedItem.title ? "selected" : ""
            }`}
            onKeyPress={() => setSelectedItem(item)}
            role="button"
            tabIndex="0"
          >
            <div className="is-flex is-hspaced">
              <p className="is-size-5 has-margin-bottom-3 is-size-6-mobile">
                {item.title}
              </p>
              {!hasInlineAnswer && (
                <span className="icon has-text-primary is-medium is-hidden-mobile rotate">
                  <ArrowIcon />
                </span>
              )}
            </div>
          </div>
          {item.title === selectedItem.title && hasInlineAnswer && (
            <motion.div
              key={selectedItem.answer}
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              className="answer-wrapper"
            >
              {selectedItem.shortAnswer && (
                <p className="is-size-6 short-answer has-margin-bottom-4 ">
                  {selectedItem.shortAnswer}
                </p>
              )}
              <p className="is-size-6 answer is-pre-line">
                {selectedItem.answer}
              </p>
            </motion.div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
FAQInpage.propTypes = {
  items: PropTypes.array.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  hasInlineAnswer: PropTypes.bool,
  selectedItem: PropTypes.object.isRequired,
}
export default FAQInpage
