import React, { useState, useRef } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useIntl } from "gatsby-plugin-intl"
import { motion, useAnimation } from "framer-motion"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Hooks
import useTranslation from "../../hooks/useTranslation"

// Icons
import OpenIcon from "../../assets/icons/open.svg"
import CreditCardIcon from "../../assets/icons/creditcards.svg"
import GlobeIcon from "../../assets/icons/global-curriencies.svg"
import WalletIcon from "../../assets/icons/wallet-2-color.svg"
import UserIcon from "../../assets/icons/user-green.svg"
import CartIcon from "../../assets/icons/shopping-cart-green.svg"
import StoreIcon from "../../assets/icons/store-green.svg"
import TruckIcon from "../../assets/icons/truck.svg"

// Shapes
import TwistedShapesLeft from "../../assets/modules-twisted-shapes-left.svg"
import TwistedShapesRight from "../../assets/modules-twisted-shapes-right.svg"
import TwistedShapesResponsive from "../../assets/module-page-twisted-shape-responsive.svg"

// Logos
import ShopifyLogoFull from "../../assets/logos/shopify-full.svg"
import TwispayWalleeLogo from "../../assets/twispay-wallee.inline.svg"
import ShopifyLogo from "../../assets/logos/shopify.svg"
import WalleeLogo from "../../assets/logos/wallee.svg"
import TwispayLogoAlt from "../../assets/twispay-logo-alt.svg"

// Components
import FAQ from "../../components/FAQ-inpage"
import ModulePageLayout from "../../components/module-page-layout"

// Styles
import "../../styles/pages/shopify.scss"

const SectionTitle = ({ title, subtitle }) => {
  return (
    <div className="section-title">
      <h2 className="title has-margin-bottom-4 is-spaced has-text-centered-mobile">
        {title}
      </h2>
      <p className="subtitle is-6 ">{subtitle}</p>
    </div>
  )
}

const HeroSection = () => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-7 has-margin-bottom-5-mobile has-margin-top-6 has-margin-top-0-mobile">
      <div className="container">
        <div className="columns">
          <div className="column is-10">
            <div className="logo-wrapper has-margin-bottom-4">
              <img src={ShopifyLogoFull} alt="Logo" />
            </div>
            <h1
              style={{ color: "#5a873e" }}
              className="title has-margin-bottom-4 is-spaced "
            >
              {t("payment-modules_module_shopify_hero_subtitle")}
            </h1>
            <p className="subtitle is-size-6-mobile has-margin-bottom-4-mobile">
              {t("payment-modules_module_shopify_subtitle")}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section2 = () => {
  const { t } = useTranslation()
  const { locale } = useIntl()
  const isWithComma = locale === "ro" || locale === "it"

  const tabs = [
    {
      key: "starter",
      text: "Starter",
    },
    {
      key: "advanced",
      text: "Advanced",
    },
    {
      key: "business",
      text: "Business",
    },
  ]
  const rates = {
    starter: {
      fee: isWithComma ? "12,95€" : "12.95€",
      transactions: 300,
      extra: isWithComma ? "0,14€" : "0.14€",
    },
    advanced: {
      fee: isWithComma ? "22,95€" : "22.95€",
      transactions: 1000,
      extra: isWithComma ? "0,10€" : "0.10€",
    },
    business: {
      fee: isWithComma ? "59,90€" : "59.90€",
      transactions: 2000,
      extra: isWithComma ? "0,08€" : "0.08€",
    },
  }
  const controls = useAnimation()
  const [selectedRate, setSelectedRate] = useState({
    key: "starter",
    rate: rates.starter,
  })
  return (
    <section className="section section-2">
      <div className="container">
        <div className="logo-wrapper has-text-centered-mobile">
          <TwispayWalleeLogo />
        </div>
        <p className="subtitle is-6 has-margin-bottom-5">
          {t("payment-modules_module_shopify_section2_subtitle")}
        </p>
        <div className="rates-section">
          <ul className="tabs-wrapper is-flex ">
            {tabs.map(tab => (
              <li key={tab.key}>
                <button
                  className={`${
                    tab.key === selectedRate.key ? "has-text-primary" : ""
                  }`}
                  onClick={async () => {
                    await controls.start({
                      opacity: 0,
                    })
                    setSelectedRate({ key: tab.key, rate: rates[tab.key] })
                    controls.start({
                      opacity: 1,
                    })
                  }}
                >
                  {tab.text}
                </button>
              </li>
            ))}
          </ul>
          <div className="is-card">
            <div className="rate-wrapper is-flex-mobile is-vcentered is-hspaced">
              <h6 className="is-size-5 has-margin-bottom-4 has-margin-bottom-0-mobile">
                {t("payment-modules_module_shopify_section2_main-title")}
              </h6>
              <p className="is-size-5-mobile">1.4%</p>
            </div>
            <div className="is-flex is-vcentered is-hspaced">
              <h6 className="is-size-5">
                {t("payment-modules_module_shopify_section2_item1_text")}
              </h6>
              <motion.span animate={controls} className="is-size-5 value">
                {selectedRate.rate.fee}
              </motion.span>
            </div>
            <div className="is-flex is-vcentered is-hspaced">
              <h6 className="is-size-5">
                {t("payment-modules_module_shopify_section2_item2_text")}
              </h6>
              <motion.span animate={controls} className="is-size-5 value">
                {selectedRate.rate.transactions}
              </motion.span>
            </div>
            <div className="is-flex is-vcentered is-hspaced">
              <h6 className="is-size-5">
                {t("payment-modules_module_shopify_section2_item3_text")}
              </h6>
              <motion.span animate={controls} className="is-size-5 value">
                {selectedRate.rate.extra}
              </motion.span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section3 = () => {
  const { t } = useTranslation()
  const processes = [
    {
      icon: ShopifyLogo,
      text: t("payment-modules_module_shopify_section3_process1"),
      link: "https://app-wallee.com/en-us/doc/shopify",
    },
    {
      icon: WalleeLogo,
      text: t("payment-modules_module_shopify_section3_process2"),
      link: "https://twispay.app-wallee.com/pricing/pricing",
    },
    {
      icon: TwispayLogoAlt,
      text: t("payment-modules_module_shopify_section3_process3"),
      link:
        "https://app-wallee.com/doc/api/processor/documentation/1537865213929/twispay",
    },
  ]
  return (
    <section className="section section-3">
      <div className="container">
        <SectionTitle
          title={t("payment-modules_module_shopify_section3_title")}
          subtitle={t("payment-modules_module_shopify_section3_subtitle")}
        />
        <div
          style={{
            gridTemplateRows: `repeat(${processes.length}, 1fr)`,
            gridTemplateColumns: `repeat(${processes.length}, 1fr)`,
          }}
          className="processes-wrapper"
        >
          {processes.map((process, i) => (
            <div
              key={process.text}
              style={{ gridArea: `${i + 1} / ${i + 1} / ${i + 2} / ${i + 2}` }}
              className="is-card is-flex is-vcentered is-hspaced"
            >
              <span className="is-flex is-vcentered">
                <span className="icon logo has-margin-right-3">
                  <img src={process.icon} alt={process.text} />
                </span>
                {process.text}
              </span>
              <a
                href="//app-wallee.com/en-us/doc/shopify"
                target="_blank"
                rel="noopener noreferrer"
                className="ex-link has-text-primary"
              >
                <span className="icon">
                  <OpenIcon />
                </span>
              </a>
              {i !== processes.length - 1 && <span className="arrow" />}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const Section4 = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <GlobeIcon />,
      text: t("payment-modules_module_shopify_section4_item1"),
    },
    {
      icon: <CreditCardIcon />,
      text: t("payment-modules_module_shopify_section4_item2"),
    },
    {
      icon: <WalletIcon />,
      text: t("payment-modules_module_shopify_section4_item3"),
    },
  ]
  return (
    <section className="section section-4 has-margin-bottom-5-mobile">
      <div className="container">
        <SectionTitle
          title={t("payment-modules_module_shopify_section4_title")}
          subtitle={t("payment-modules_module_shopify_section4_subtitle")}
        />
        <div className="items-wrapper ">
          {items.map((item, i) => (
            <React.Fragment key={item.text}>
              <div
                style={{
                  gridArea: `1 / ${i + 1} / 2 / ${i + 2}`,
                }}
                className="has-text-centered icon-wrapper has-text-left-mobile"
              >
                <span className="icon">{item.icon}</span>
              </div>
              {i !== items.length - 1 && (
                <span
                  style={{
                    gridArea: `1 / ${i + 1} / 2 / ${i + 3}`,
                  }}
                  className="connecting-arrow"
                />
              )}
              <p
                style={{
                  gridArea: `2 / ${i + 1} / 3 / ${i + 2}`,
                }}
                className="text has-text-white is-size-6"
              >
                <span className="item-number has-background-white has-text-default has-margin-right-3">
                  {i + 1}
                </span>
                {item.text}
              </p>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

const Section5 = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <UserIcon />,
      text: t("payment-modules_module_shopify_section5_item1"),
    },
    {
      icon: <CartIcon />,
      text: t("payment-modules_module_shopify_section5_item2"),
    },
    {
      icon: <StoreIcon />,
      text: t("payment-modules_module_shopify_section5_item3"),
    },
  ]
  return (
    <section className="section section-5 ">
      <div className="container">
        <SectionTitle
          title={t("payment-modules_module_shopify_section5_title")}
          subtitle={t("payment-modules_module_shopify_section5_subtitle")}
        />
        <div className="items-wrapper">
          {items.map((item, i) => (
            <React.Fragment key={item.text}>
              <div
                style={{
                  gridArea: `1 / ${i + 1} / 2 / ${i + 2}`,
                }}
                className="has-text-centered icon-wrapper has-text-left-mobile"
              >
                <span className="icon">{item.icon}</span>
              </div>
              {i !== items.length - 1 && (
                <span
                  style={{
                    gridArea: `1 / ${i + 1} / 2 / ${i + 3}`,
                  }}
                  className="connecting-arrow"
                />
              )}
              <p
                style={{
                  gridArea: `2 / ${i + 1} / 3 / ${i + 2}`,
                }}
                className="text is-size-6"
              >
                {item.text}
              </p>
            </React.Fragment>
          ))}
          <div className="back-arrow">
            <div className="arrow left is-hidden-mobile" />
            <div className="arrow right is-hidden-mobile" />
          </div>
          <div className="truck-icon-wrapper has-text-centered">
            <span className="icon ">
              <TruckIcon />
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section6 = () => {
  const { t } = useTranslation()
  const { formatMessage } = useIntl()
  const MODULE = "Shopify"
  const items = [
    {
      title: t("payment-modules_module_faq_Q1"),
      answer: t("payment-modules_module_faq_A1"),
    },
    {
      title: t("payment-modules_module_faq_Q2"),
      answer: t("payment-modules_module_faq_A2"),
    },
    {
      title: formatMessage({ id: "payment-modules_module_faq_Q3" }, { MODULE }),
      answer: formatMessage(
        { id: "payment-modules_module_faq_A3" },
        { MODULE }
      ),
    },
    {
      title: formatMessage({ id: "payment-modules_module_faq_Q4" }, { MODULE }),
      answer: formatMessage(
        { id: "payment-modules_module_faq_A4" },
        { MODULE }
      ),
    },
    {
      title: formatMessage({ id: "payment-modules_module_faq_Q5" }, { MODULE }),
      answer: formatMessage(
        { id: "payment-modules_module_faq_A5" },
        { MODULE }
      ),
    },
  ]
  const [selectedItem, setSelectedItem] = useState(items[0])
  return (
    <section className="section section-6">
      <div className="container">
        <h3 className="title is-spaced main-title has-text-centered">
          {t("Frequently Asked Questions")}
        </h3>
        <FAQ
          items={items}
          hasInlineAnswer
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
    </section>
  )
}

const ShopifyPage = () => {
  const { t } = useTranslation()
  const [widgetVisible, setWidgetVisible] = useState(true)
  const section6Ref = useRef()
  useScrollPosition(
    ({ currPos: { y } }) => {
      if (y < 80) setWidgetVisible(false)
      else setWidgetVisible(true)
    },
    [],
    section6Ref
  )
  return (
    <Layout
      stripeColor="#f2f6ee"
      pageName="shopify-module-page"
      hasLightBackground
      noMarginTopFooter
      TwistedShapesLeft={TwistedShapesLeft}
      TwistedShapesRight={TwistedShapesRight}
      TwistedShapesResponsive={TwistedShapesResponsive}
    >
      <ModulePageLayout widgetVisible={widgetVisible}>
        <SEO title={t("Payment Services for Shopify")} />
        <HeroSection />
        <Section2 />
        <Section3 />
      </ModulePageLayout>
      <div className="has-stripe-background">
        <div className="container">
          <div className="columns">
            <div className="column is-7 is-offset-3">
              <Section4 />
              <Section5 />
              <div ref={section6Ref}>
                <Section6 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShopifyPage
