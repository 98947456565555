import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"
import { window } from "browser-monads"
import { Link, useIntl } from "gatsby-plugin-intl"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Logos
import WooLogo from "../assets/logos/woo.svg"
import ShopifyLogo from "../assets/logos/shopify.svg"
import PrestashopLogo from "../assets/logos/prestashop.svg"
import OpencartLogo from "../assets/logos/opencart-full.svg"
import MagentoLogo from "../assets/logos/magento-full.svg"
import OscommerceLogo from "../assets/logos/oscommerce-full.png"
import WhmcsLogo from "../assets/logos/whmcs.jpg"

// Shapes
import Shape2 from "../assets/modules-shape-2.svg"
import Shape4 from "../assets/modules-shape-4.svg"

// Icons
import ArrowRightIcon from "../assets/icons/arrow-right.svg"

// Styles
import "../styles/components/module-page-layout.scss"

const FloatingWidget = () => {
  const { t } = useTranslation()
  const { formatMessage } = useIntl()
  const [topValue, setTopValue] = useState(223)
  const [positionValue, setPositionValue] = useState("fixed")
  const handleHeightResize = () => {
    if (window.innerHeight < 960) {
      setPositionValue("absolute")
      setTopValue(115)
    } else setPositionValue("fixed")
  }
  useEffect(() => {
    window.addEventListener("resize", handleHeightResize)
    return () => window.removeEventListener("resize", handleHeightResize)
  }, [])
  useEffect(() => {
    handleHeightResize()
  }, [])
  const modules = [
    {
      logo: ShopifyLogo,
      title: "Shopify",
      link: "/payment-modules/shopify",
    },
    {
      logo: WooLogo,
      title: "WooCommerce",
      link: "/payment-modules/woocommerce",
    },
    {
      logo: PrestashopLogo,
      title: "PrestaShop",
      link: "/payment-modules/prestashop",
    },
  ]
  const otherModules = [
    { logo: MagentoLogo, height: 28 },
    { logo: OpencartLogo, height: 24 },
    { logo: OscommerceLogo, height: 24 },
    { logo: WhmcsLogo, height: 19 },
  ]
  useScrollPosition(
    ({ currPos: { y } }) => {
      if (positionValue === "fixed") {
        if (y < -115) setTopValue(100)
        else setTopValue(223 + y - 24)
      }
    },
    [positionValue]
  )
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container is-hidden-touch"
    >
      <div className="module-widget-wrapper">
        <div className="shapes-wrapper">
          <img className="shape shape-4 " src={Shape4} alt="Shape" />
        </div>
        <div
          className="content-wrapper"
          style={{ top: topValue, position: positionValue }}
        >
          <div className="widget-title is-flex is-vcentered">
            <h6 className="has-text-white title">
              {t("payment-modules_widget_title")}
            </h6>
            <img className="shape shape-2" src={Shape2} alt="Shape" />
          </div>
          <div className="content-area">
            <div className="has-margin-bottom-5">
              <h6 className="title">{t("payment-modules_widget_subtitle1")}</h6>
              {modules.map(module =>
                module.exlink ? (
                  <a
                    key={module.title}
                    href={module.exlink}
                    className="is-flex is-hspaced is-vcentered module-wrapper"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="is-flex link-text is-vcentered">
                      <img
                        width="40"
                        src={module.logo}
                        alt={module.title}
                        className="has-margin-right-4"
                      />
                      {module.title}
                    </span>
                    <span className="icon arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </a>
                ) : (
                  <Link
                    key={module.title}
                    to={module.link}
                    className="is-flex is-hspaced is-vcentered module-wrapper"
                  >
                    <span className="is-flex link-text is-vcentered">
                      <img
                        width="40"
                        src={module.logo}
                        alt={module.title}
                        className="has-margin-right-4"
                      />
                      {module.title}
                    </span>
                    <span className="icon arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </Link>
                )
              )}
            </div>
            <div>
              <h6 className="title">{t("payment-modules_widget_subtitle2")}</h6>
              <p className="has-margin-bottom-5">
                {formatMessage(
                  {
                    id: "payment-modules_widget_text",
                  },
                  {
                    talk: (
                      <Link to="/contact" className="has-text-primary">
                        {t("payment-modules_widget_text_link")}
                      </Link>
                    ),
                  }
                )}
              </p>
              <div style={{ flexWrap: "wrap" }} className="is-flex is-hspaced">
                {otherModules.map(module => (
                  <img
                    style={{ height: module.height }}
                    key={module.logo}
                    src={module.logo}
                    className="has-margin-bottom-4"
                    alt="Module Logo"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const ModulePageLayout = ({ children, widgetVisible }) => {
  return (
    <>
      <AnimatePresence>{widgetVisible && <FloatingWidget />}</AnimatePresence>
      <div className="container ">
        <div className="columns">
          <div className="column is-7 is-offset-3">{children}</div>
        </div>
      </div>
    </>
  )
}
ModulePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModulePageLayout
